import React, { useEffect, useRef } from "react"
import Footer from "../../components/footer"
import { graphql } from "gatsby"
import "../../styles/rhs.less"
import SEO from "../../components/SEO"
import { SolutionTopBanner } from "../../components/block"
// import { GetStarted } from "../index"
import { RhsFormButton } from "../../components/form-biz"
const loadText = require("src/utils").loadText
const data = loadText("remote-hand-serveices")
const macy = typeof window !== "undefined" ? require("macy") : function () {}
export const colors = [
  "linear-gradient(#FAF4FF, #F4E6FF)",
  "linear-gradient(#FFFDF2, #FFF9E1)",
  "linear-gradient(#F1FBFB, #DEF6F5)",
  "linear-gradient(#F2FDFF, #E0F9FF)",
  "linear-gradient(#F2FBFF, #E1F5FF)",
  "linear-gradient(#F5F6FF, #E7EAFF)",
]
const Content = () => {
  const container = useRef()
  useEffect(() => {
    new macy({
      container: container.current,
      columns: 2,
      margin: 24,
      breakAt: {
        760: {
          columns: 1,
        },
      },
    })
  }, [])
  return (
    <div className="rhs-content page-middle">
      <div className="font-36 semi-bold t-color">{data.content.title}</div>
      <div className="rhs-content-itemContainer mt-64" ref={container}>
        {data.content.data.map(({ name, icon, content, color }, i) => (
          <div className="rhs-content-item" style={{ background: color }}>
            <img src={`/products/rhs/${icon}.png`} alt={name} />
            <div className="font-28 semi-bold t-color text-center mt-32">
              {name}
            </div>
            <ul className="mt-16">
              {content.map(c => (
                <li className="font-20">{c}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
export default function Home() {
  return (
    <div className="rhs">
      <SEO {...data.seo} />
      <SolutionTopBanner
        {...data.topBanner}
        bgColor="linear-gradient(180deg,#F2FDF7 0%, #E0F9EC 100%)"
        bgPic="/products/rhs/topBanner-bg.svg"
        pic={{
          src: "/products/rhs/topBanner-pic.png",
          style: { position: "absolute", bottom: "-40px", right: "70px" },
        }}
        bannerPic={{
          src: "/products/rhs/proBanner.svg",
          style: { position: "absolute", bottom: "-40px", right: "70px" },
        }}
        button={RhsFormButton}
      />
      <Content />
      <Footer />
    </div>
  )
}
